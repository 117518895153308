<template>
  <div class="weighingScale" id="weighingScale">
    <div class="weighingScale_top" id="weighingScale_top">
      <div class="weighingScale_top_t">秤要精准 也要颜值</div>
      <el-button class="weighingScale_top_btn" type="success" plain @click="toBuy">立即购买</el-button>
    </div>
    <div class="weighingScale_bottom">
      <div>
        <div v-for="item in imgList">
          <img :src="item" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "weighingScale",
    data() {
      return {
        imgList: [
          require('../../assets/image/w_d_i_1.jpg'),
          require('../../assets/image/w_d_i_2.jpg'),
          require('../../assets/image/w_d_i_3.jpg'),
          require('../../assets/image/w_d_i_4.jpg'),
          require('../../assets/image/w_d_i_5.jpg'),
          require('../../assets/image/w_d_i_6.jpg'),
          require('../../assets/image/w_d_i_7.jpg'),
          require('../../assets/image/w_d_i_8.jpg'),
          require('../../assets/image/w_d_i_9.jpg'),
          require('../../assets/image/w_d_i_10.jpg'),
          require('../../assets/image/w_d_i_11.jpg'),
          require('../../assets/image/w_d_i_12.jpg'),
          require('../../assets/image/w_d_i_13.jpg')
        ]
      }
    },
    mounted() {
      this.topFunction()
      setTimeout(() => {
        window.onscroll = function() {
          let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          console.log('页面滚动：', scrollTop)
          if (scrollTop > 0) {
            let process = document.getElementById('header-w')
            let ylWatchTop = document.getElementById('weighingScale_top')
            process.style.display = 'none'
            ylWatchTop.style.top = '0'
            process.style.zIndex = '9999 !important'
          } else {
            let process = document.getElementById('header-w')
            let ylWatchTop = document.getElementById('weighingScale_top')
            process.style.display = 'block'
            ylWatchTop.style.top = 73 / 30 * 32 + 'px'
          }
        }
      },500)
    },
    methods: {
      topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      // 跳转天猫购买
      toBuy() {
        window.open('https://bailaiqiao.tmall.com/?spm=a220o.1000855.1997427133.d4918061.398b7286shY1m0')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @function torem($px){//$px为需要转换的字号
    @return $px / 30px * 1rem; //100px为根字体大小
  }
  .weighingScale {
    background: #FFFFFF;
    &_top {
      position: fixed;
      width: 100vw;
      top: torem(73px);
      background: #ffffff;
      border: torem(1px) solid #EEEEEE;
      height: torem(70px);
      line-height: torem(70px);
      display: flex;
      align-items: center;
      justify-content: space-around;
      &_t {
        font-size: torem(24px);
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      &_btn {
        width: torem(122px);
        height: torem(40px);
        background: #30C159;
        border-radius: torem(20px);
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &_bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: torem(68px);
    }
  }
  /*修改滚动条样式*/
  .weighingScale::-webkit-scrollbar{
    width: 0px;
    /*height: 30px;*/
  }
  .weighingScale::-webkit-scrollbar-track{
    border-radius: 0px;
  }
  .weighingScale::-webkit-scrollbar-thumb{
    background: #D9D9D9;
    border-radius: 0px;
  }
  .weighingScale::-webkit-scrollbar-thumb:hover{
    background: #D9D9D9;
    border-radius: 0px;
  }
  .weighingScale::-webkit-scrollbar-corner{
    border-radius: 0px;
  }
</style>